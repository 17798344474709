import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline'

import Layout from './layout/Layout'
import AppContextProvider from './AppContextProvider';
import Loading from './components/Loading';

const HomePage = lazy(() => import('./pages/HomePage'));
const AccountPage = lazy(() => import('./pages/AccountPage'));
const GroupPage = lazy(() => import('./pages/GroupPage'));
const CreateGroupPage = lazy(() => import('./pages/CreateGroupPage'));
const CalendarPage = lazy(() => import('./pages/CalendarPage'));
const EventPage = lazy(() => import('./pages/EventPage'));
const CreateEventPage = lazy(() => import('./pages/CreateEventPage'));

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <AppContextProvider>
        <CssBaseline />
        <Suspense fallback={<Loading show />}>
          <Layout>
            <Suspense fallback={<Loading show />}>
              <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/account" element={<AccountPage />} />
                  <Route path="/account/:tab" element={<AccountPage />} />
                  <Route path="/groups/create" element={<CreateGroupPage />} />
                  <Route path="/groups/:id" element={<GroupPage />} />
                  <Route path="/groups/:id/:tab" element={<GroupPage />} />
                  <Route path="/calendar" element={<CalendarPage />} />
                  <Route path="/events/create" element={<CreateEventPage />} />
                  <Route path="/events/:id" element={<EventPage />} />
                  <Route path="/events/:id/:startTime" element={<EventPage />} />
              </Routes>
            </Suspense>
          </Layout>
        </Suspense>
      </AppContextProvider>
    </BrowserRouter>
  );
}

export default App;
