import React from 'react';
import { useNavigate } from 'react-router-dom'
import { useAuth } from "react-oidc-context";
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

export interface SettingsMenuProps extends MenuProps {
  onClose?: () => void,
}

const SettingsMenu: React.FC<SettingsMenuProps> = props => {
  const navigate = useNavigate();
  const { signoutRedirect } = useAuth();

  const handleClose = () => {
    if (props.onClose !== undefined) props.onClose();
  }

  const handleSettings = () => {
    navigate("/account");
    handleClose();
  }

  const handleLogout = () => {
    signoutRedirect();
    handleClose();
  }

  return (
    <Menu {...props}>
      <MenuItem onClick={handleSettings}> Settings </MenuItem>
      <MenuItem onClick={handleLogout}> Logout </MenuItem>
    </Menu>
  )
}

export default SettingsMenu;
