import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Avatar, { AvatarProps } from '@material-ui/core/Avatar';
import { FragmentType, useFragment } from '../gql/fragment-masking'
import { graphql } from '../gql'

export const UserAvatarFragment = graphql(`
  fragment UserAvatar on User {
    firstName
    lastName
    avatar
  }
`);

const useStyles = makeStyles((theme) => ({
  avatarSize: {
    width: (props: UserAvatarProps) => theme.spacing(props.size),
    height: (props: UserAvatarProps) => theme.spacing(props.size),
    fontSize: (props: UserAvatarProps) => props.size * 4,
  },
}));

export interface UserAvatarProps extends AvatarProps {
  user: FragmentType<typeof UserAvatarFragment>;
  size: number;
}

const UserAvatar: React.FC<UserAvatarProps> = props => {
  const { user: userProp, className, ...avatarProps } = props
  const classes = useStyles(props);
  const user = useFragment(UserAvatarFragment, userProp)

  return (
    <Avatar
      alt={`${user.firstName} ${user.lastName}`}
      src={user.avatar ?? undefined}
      className={clsx(className, classes.avatarSize)}
      {...avatarProps}
    >
      {`${user.firstName[0] ?? ""}${user.lastName[0] ?? ""}`}
    </Avatar>
  )
}

export default UserAvatar;
