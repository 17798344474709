import React, { useState, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom'
import clsx from 'clsx';
import { useQuery } from 'urql';
import debounce from 'lodash/debounce';
import { alpha, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import InputBase from '@material-ui/core/InputBase';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import { useTheme } from '@material-ui/core/styles';
import MainAppBarRightContainer from './MainAppBarRightContainer'
import GroupAvatar from '../components/groups/GroupAvatar'
import { FragmentType, useFragment } from '../gql/fragment-masking'
import { graphql } from '../gql'

export const MainAppBarUserFragment = graphql(`
  fragment MainAppBarUser on User {
    ...MainAppBarRightContainerUser
  }
`);

export const SearchQuery = graphql(`
  query Search($filter: SearchInput) {
    search(filter: $filter) {
      edges {
        node {
          ... on Group {
            id
            name
            ...GroupAvatar
          }
        }
      }
    }
  }
`);

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: (props: MainAppBarProps) => `calc(100% - ${props.drawerWidth}px)`,
    marginLeft: (props: MainAppBarProps) => props.drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  title: {
    display: 'none',
    color: 'inherit',
    'text-decoration': 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchBar: {
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '40ch',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    // paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
  },
}));

export type MainAppBarProps = {
  drawer: boolean
  drawerWidth: number
  drawerOpen: boolean
  setDrawerOpen: (open: boolean) => void
  user?: FragmentType<typeof MainAppBarUserFragment>
}

const MainAppBar: React.FC<MainAppBarProps> = props => {
  const classes = useStyles(props);
  const theme = useTheme();

  const user = useFragment(MainAppBarUserFragment, props.user)
  const [searchInputValue, setSearchInputValue] = useState("");
  const setSearchInputValueDebounced = useCallback(debounce(setSearchInputValue, 200), [setSearchInputValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const [{ data, fetching }] = useQuery({
    query: SearchQuery,
    pause: !searchInputValue,
    variables: { filter: { contains: searchInputValue } },
    context: useMemo(() => { return { suspense: false } }, [])
  })

  return (
    <>
      <AppBar
        color={theme.palette.type === "dark" ? "default" : "primary"}
        position="fixed"
        className={clsx(classes.appBar, { [classes.appBarShift]: props.drawerOpen })}
      >
        <Toolbar>
          {props.drawer && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              className={clsx(classes.menuButton, props.drawerOpen && classes.hide)}
              onClick={() => props.setDrawerOpen(true)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Link to="/" className={classes.title} >
            <Typography variant="h6" noWrap>
              Catchplacement
            </Typography>
          </Link>
          <div className={classes.searchBar}>
            <Autocomplete
              id="tags-standard"
              fullWidth
              options={data?.search.edges ?? []}
              loading={fetching}
              getOptionLabel={(option) => option.node.name}
              getOptionSelected={(option, value) => option.node.id === value.node.id}
              renderOption={(option) => (
                <ListItem
                  button
                  component={Link}
                  to={`/groups/${option.node.id}`}
                >
                  <ListItemAvatar>
                    <GroupAvatar group={option.node} size={4} />
                  </ListItemAvatar>
                  <ListItemText primary={option.node.name} />
                </ListItem>
              )}
              renderInput={(params) => {
                const { InputProps, InputLabelProps, ...rest } = params
                return (
                  <div className={classes.search}>
                    <InputBase
                      placeholder="Search…"
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      onChange={(event) => { setSearchInputValueDebounced(event.target.value) }}
                      {...InputProps}
                      {...rest}
                      startAdornment={<div className={classes.searchIcon}><SearchIcon /></div>}
                      endAdornment={fetching ? <CircularProgress color="inherit" size={20} /> : null}
                    />
                  </div>
                )
              }}
            />
          </div>
          <div className={classes.grow} />
          <MainAppBarRightContainer user={user ? user : undefined}/>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default MainAppBar;
