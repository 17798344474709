import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Provider as UrqlProvider, createClient, dedupExchange } from 'urql';
import { makeOperation } from '@urql/core';
import { authExchange } from '@urql/exchange-auth';
import { multipartFetchExchange } from '@urql/exchange-multipart-fetch';
import { cacheExchange } from '@urql/exchange-graphcache';
import { AuthProvider, useAuth } from "react-oidc-context";

import { ThemeProvider } from './Themes'

export type OidcUrqlProviderProps = {
  readonly children: React.ReactNode
}

const OidcUrqlProvider: React.FC<OidcUrqlProviderProps> = props => {
  const { user } = useAuth();
  /*
   * TODO: This memo sets us up to be able to recreate the client every time the access_token changes.
   * This clears the cache though. URQL recommends setting up some sort of event emitter so that the willAuthError function
   * can cause a refetch of the access token.
   */
  const urqlClient = useMemo(() => createClient({
    url: `${process.env.REACT_APP_GRAPHQL_HOST}/graphql`,
    suspense: true,
    exchanges: [
      dedupExchange,
      cacheExchange({
        keys: {
          GroupMember: data => null,
          GroupMemberInfo: data => null,
          EventGuest: data => null,
          EventGuestInfo: data => null,
        }
      }),
      authExchange({
        addAuthToOperation: ({ authState, operation }) => {
          if (!authState?.token) return operation;
          return makeOperation(
            operation.kind,
            operation,
            {
              ...operation.context,
              fetchOptions: {
                headers: {
                  "authorization": `Bearer ${authState.token}`,
                },
              },
            },
          );
        },
        getAuth: async () => ({ token: user?.access_token }),
      }),
      multipartFetchExchange,
    ]
  }), [user?.access_token])

  return (
    <UrqlProvider value={urqlClient}>
      {props.children}
    </UrqlProvider>
  )
}

export type AuthenticationRequiredProps = {
  readonly children: React.ReactNode
}

/**
 * Wrap the provided children in a component that optionally blocks all children from being rendered if the user is not logged in.
 * @param props 
 */
const AuthenticationRequired: React.FC<AuthenticationRequiredProps> = props => {
  const {signinRedirect, isAuthenticated, isLoading} = useAuth();

  if (!isAuthenticated && !isLoading) {
    signinRedirect()
    return <> Redirecting to login... </>
  }

  return <> {props.children} </>;
}

export type AppContextProviderProps = {
  readonly children: React.ReactNode
}

/**
 * Wrap the provided children in all of the context providers for the app.
 * @param props 
 */
const AppContextProvider: React.FC<AppContextProviderProps> = props => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <AuthProvider
      authority={process.env.REACT_APP_OIDC_AUTHORITY ?? ""}
      client_id={process.env.REACT_APP_OIDC_CLIENT_ID ?? ""}
      extraQueryParams={{audience: process.env.REACT_APP_OIDC_AUDIENCE ?? ""}}
      scope="offline_access offline openid email"
      redirect_uri={window.location.origin}
      post_logout_redirect_uri={window.location.origin}
      onSigninCallback={() => {
        // Remove the extra parameters added by the /authorize endpoint upon redirect
        searchParams.delete("code")
        searchParams.delete("scope")
        searchParams.delete("state")
        setSearchParams(searchParams)
      }}
    >
      {process.env.REACT_APP_LOGIN_REQUIRED === "true" ? (
        <AuthenticationRequired>
          <ThemeProvider>
            <OidcUrqlProvider>
              {props.children}
            </OidcUrqlProvider>
          </ThemeProvider>
        </AuthenticationRequired>
      ) : (
          <ThemeProvider>
            <OidcUrqlProvider>
              {props.children}
            </OidcUrqlProvider>
          </ThemeProvider>
        )}
    </AuthProvider>
  );
}

export default AppContextProvider;
