import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop, { BackdropProps } from '@material-ui/core/Backdrop';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';


const useStyles = makeStyles(theme => ({
    loadingBackdrop: {
      zIndex: theme.zIndex.mobileStepper,
      position: 'absolute',
    },
  }));


export interface LoadingProps extends Omit<BackdropProps, 'open'> {
  show: boolean,
  variant?: CircularProgressProps['variant'],
  value?: CircularProgressProps['value'],
}

const Loading: React.FC<LoadingProps> = props => {
    const classes = useStyles();
    const { show, className, transitionDuration = {enter: 1000}, variant = 'indeterminate', value = 60, ...rest } = props
  
    return (
      <Backdrop
        className={clsx(className, classes.loadingBackdrop)}
        open={show}
        transitionDuration={transitionDuration}
        {...rest}
      >
        <CircularProgress
          variant={variant}
          value={value}
        />
      </Backdrop>
    )
  }
  
  export default Loading;
  