import React from 'react';
import { useMutation } from 'urql';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useAuth } from "react-oidc-context";
import { Formik } from "formik";
import * as Yup from "yup";
import { graphql } from '../gql'

export const createUserMutation = graphql(`
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      user {
        id
      }
    }
  }
`);

export interface CreateUserDialogProps {
  open: boolean
  onClose: () => void
}

const CreateUserDialog: React.FC<CreateUserDialogProps> = props => {
  const { user } = useAuth();
  const [{ fetching }, createUser] = useMutation(createUserMutation)

  // If the user is still loading, render an empty dialog.
  if (!user) return <Dialog open={props.open} />

  return (
    <Dialog open={props.open} aria-labelledby="create-user-dialog-title">
      <DialogTitle id="create-user-dialog-title">Welcome!</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Thank you for joining Catchplacement.com! Please fill out some more information before getting started.
        </DialogContentText>
        <Formik
          initialValues={{ firstName: user.profile.given_name ?? "", lastName: user.profile.family_name ?? "" }}
          onSubmit={async (values) => {
            await createUser({ input: { email: user.profile.email!, avatar: user.profile.picture, ...values } });
            props.onClose();
          }}
          validationSchema={Yup.object().shape({
            firstName: Yup.string().required("Required"),
            lastName: Yup.string().required("Required"),
          })}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;
            return (
              <form onSubmit={handleSubmit}>
                <TextField
                  label="First Name"
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={(errors.firstName && touched.firstName) && errors.firstName}
                  fullWidth
                  autoFocus
                />
                <TextField
                  label="Last Name"
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={(errors.lastName && touched.lastName) && errors.lastName}
                  fullWidth
                />
                <DialogActions>
                  <Button type="submit" disabled={fetching} variant="contained" color="primary">
                    Submit
                  </Button>
                </DialogActions>
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

export default CreateUserDialog
