import React from 'react';
import { NavLink as NavLinkBase } from 'react-router-dom'; 

const NavLink = React.forwardRef<any, any>((props, ref) => (
  <NavLinkBase
    ref={ref}
    {...props}
    className={props.activeClassName}
  />
));

export default NavLink