import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer, { DrawerProps } from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import HomeIcon from '@material-ui/icons/Home';
import GroupAvatar from '../components/groups/GroupAvatar'
import NavLink from '../components/NavLink';
import { FragmentType, useFragment } from '../gql/fragment-masking'
import { graphql } from '../gql'

export const sideDrawerWidth = 240;

export const SideDrawerUserFragment = graphql(`
  fragment SideDrawerUser on User {
    groups {
      edges {
        node {
          id
          name
          ...GroupAvatar
        }
      }
    }
  }
`);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: (props: SideDrawerProps) => props.drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: (props: SideDrawerProps) => props.drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
}));

export interface SideDrawerProps extends DrawerProps {
  drawerWidth: number;
  user?: FragmentType<typeof SideDrawerUserFragment>
}

const SideDrawer: React.FC<SideDrawerProps> = props => {
  const { drawerWidth, user: userProp, ...drawerProps } = props
  const classes = useStyles(props);
  const theme = useTheme();
  const user = useFragment(SideDrawerUserFragment, userProp)

  const handleNavSelect = () => {
    if (drawerProps.onClose && drawerProps.variant === "temporary") {
      drawerProps.onClose({}, 'backdropClick');
    }
  };

  return (
    <Drawer
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      {...drawerProps}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={() => { if (props.onClose) props.onClose({}, 'backdropClick'); }}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>
      <Divider />
      <List>
        <ListItem button key="Home" component={NavLink} to={"/"} exact activeClassName="Mui-selected" onClick={handleNavSelect}>
          <ListItemIcon><HomeIcon /></ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        {user &&
          <ListItem button key="Calendar" component={NavLink} to={"/calendar/"} exact activeClassName="Mui-selected" onClick={handleNavSelect}>
            <ListItemIcon><CalendarIcon /></ListItemIcon>
            <ListItemText primary="Calendar" />
          </ListItem>
        }
      </List>
      <Divider />
      { user?.groups?.edges &&
        <>
          <List
            aria-labelledby="groups-list-subheader"
            subheader={
              <ListSubheader component="div" id="groups-list-subheader">
                Groups
              </ListSubheader>
            }
          >
            {user.groups.edges.map((edge, index) => {
              return (
                <ListItem
                  button
                  key={edge.node.id}
                  component={NavLink}
                  to={`/groups/${edge.node.id}`}
                  activeClassName="Mui-selected"
                  onClick={handleNavSelect}
                >
                  <ListItemAvatar>
                    <GroupAvatar group={edge.node} size={5} />
                  </ListItemAvatar>
                  <ListItemText primary={edge.node.name} />
                </ListItem>
              )
            })}
          </List>
          <Divider />
        </>
      }
    </Drawer>
  );
}

export default SideDrawer;
