import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'urql';
import { makeStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';
import DoneIcon from '@material-ui/icons/Done';
import IconButton from '@material-ui/core/IconButton';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreIcon from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography';
import Moment from 'react-moment';
import UserAvatar from '../UserAvatar'
import { FragmentType, useFragment } from '../../gql/fragment-masking'
import { graphql } from '../../gql'

export const NotificationMenuConnectionFragment = graphql(`
  fragment NotificationMenuConnection on NotificationConnection {
    edges {
      node {
        id
        read
        createdAt
        ... on GroupInviteNotification {
          group {
            id
            name
          }
          invitedBy {
            id
            ...UserAvatar
            firstName
            lastName
          }
        }
      }
    }
  }
`);

export const MARK_NOTIFICATION_AS_READ = graphql(`
  mutation MarkNotificationAsRead($input: MarkNotificationAsReadInput!) {
    markNotificationAsRead(input: $input) {
      notification {
        id
        read
      }
    }
  }
`);

export const MARK_NOTIFICATION_AS_UNREAD = graphql(`
  mutation MarkNotificationAsUnread($input: MarkNotificationAsUnreadInput!) {
    markNotificationAsUnread(input: $input) {
      notification {
        id
        read
      }
    }
  }
`);

export const MARK_ALL_NOTIFICATIONS_AS_READ = graphql(`
  mutation MarkAllNotificationsAsRead {
    markAllNotificationsAsRead {
      notifications {
        id
        read
      }
    }
  }
`);

const useStyles = makeStyles((theme) => ({
  notificationMenu: {
    width: 400,
  },
  menuText: {
    margin: theme.spacing(0, 0.5, 0, 1),
  },
}));

export interface NotificationMenuProps extends MenuProps {
  notifications: FragmentType<typeof NotificationMenuConnectionFragment>,
  handleNotificationMenuClose: () => void,
}

const NotificationMenu: React.FC<NotificationMenuProps> = props => {
  const { notifications: notificationsProp, handleNotificationMenuClose, ...menuProps } = props
  const classes = useStyles(props);
  const navigate = useNavigate();
  const notifications = useFragment(NotificationMenuConnectionFragment, notificationsProp)
  const [selectedNotification, setSelectedNotification] = useState<typeof notifications['edges'][0]['node'] | null>(null);
  const [notificationMenuAnchorEl, setNotificationMenuAnchorEl] = useState(null);
  const [notificationMenuOptionsAnchorEl, setNotificationMenuOptionsAnchorEl] = useState(null);
  const [, markNotificationAsRead] = useMutation(MARK_NOTIFICATION_AS_READ)
  const [, markNotificationAsUnread] = useMutation(MARK_NOTIFICATION_AS_UNREAD)
  const [, markAllNotificationsAsRead] = useMutation(MARK_ALL_NOTIFICATIONS_AS_READ)

  return (
    <>
      <Menu onClose={handleNotificationMenuClose} {...menuProps}>
        <CardHeader title="Notifications" action={
          <IconButton
            aria-label="Open notification options menu"
            onClick={(event: any) => {
              setNotificationMenuOptionsAnchorEl(event.currentTarget)
            }}
          >
            <MoreIcon />
          </IconButton>
        } />
        {notifications.edges.map((edge) => {
          // TODO: render a dot or something to show notifications that were read.
          // TODO: Set notifications that are clicked on as read.
          // TODO: create context menu for marking all as read or clearing all notifications.
          switch (edge.node.__typename) {
            case "GroupInviteNotification":
              return (
                <MenuItem
                  className={classes.notificationMenu}
                  button
                  key={edge.node.id}
                  onClick={() => {
                    markNotificationAsRead({ input: { notificationId: edge.node.id } })
                    navigate("/account/groups");
                    handleNotificationMenuClose();
                  }}
                >
                  <ListItemAvatar>
                    <UserAvatar user={edge.node.invitedBy} size={5} />
                  </ListItemAvatar>
                  <ListItemText
                    primaryTypographyProps={{ style: { whiteSpace: "normal" } }}
                    primary={
                      <>
                        <Typography
                          color={edge.node.read ? "textSecondary" : "textPrimary"}
                          style={{ whiteSpace: "normal" }}
                        >
                          {edge.node.invitedBy.firstName} {edge.node.invitedBy.lastName} invited you to join the group {edge.node.group.name}
                        </Typography>
                      </>
                    }
                    secondary={<Moment fromNow utc date={edge.node.createdAt} />}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="Open notification menu"
                      onClick={(event: any) => {
                        setSelectedNotification(edge.node)
                        setNotificationMenuAnchorEl(event.currentTarget)
                      }}
                    >
                      <MoreIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </MenuItem>
              );
            default:
              return null;
          }
        })}
      </Menu>
      <Menu
        anchorEl={notificationMenuAnchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        getContentAnchorEl={null}
        id={"notification-menu"}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(notificationMenuAnchorEl)}
        onClose={() => { setNotificationMenuAnchorEl(null) }}
      >
        {selectedNotification && (selectedNotification.read ? (
          <MenuItem
            aria-label="mark this notification as unread"
            onClick={() => {
              markNotificationAsUnread({ input: { notificationId: selectedNotification.id } })
              setNotificationMenuAnchorEl(null)
            }}
          >
            <DoneIcon />
            <span className={classes.menuText}>
              Mark as Unread
            </span>
          </MenuItem>
        ) : (
            <MenuItem
              aria-label="mark this notification as read"
              onClick={() => {
                markNotificationAsRead({ input: { notificationId: selectedNotification.id } })
                setNotificationMenuAnchorEl(null)
              }}
            >
              <DoneIcon />
              <span className={classes.menuText}>
                Mark as Read
            </span>
            </MenuItem>
          ))}
      </Menu>
      <Menu
        anchorEl={notificationMenuOptionsAnchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        getContentAnchorEl={null}
        id={"notification-menu-options"}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(notificationMenuOptionsAnchorEl)}
        onClose={() => { setNotificationMenuOptionsAnchorEl(null) }}
      >
        <MenuItem
          aria-label="mark all notifications as read"
          onClick={() => {
            markAllNotificationsAsRead({})
            setNotificationMenuOptionsAnchorEl(null)
          }}
        >
          <DoneIcon />
          <span className={classes.menuText}>
            Mark all as Read
          </span>
        </MenuItem>
      </Menu>
    </>
  )
}

export default NotificationMenu;
