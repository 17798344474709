import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useQuery, useMutation } from 'urql';
import { useAuth } from "react-oidc-context";
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import MainAppBar from './MainAppBar'
import SideDrawer from './SideDrawer'
import CreateUserDialog, { createUserMutation } from './CreateUserDialog'
import { useThemeTypeSetter } from '../Themes'
import { graphql } from '../gql'

const drawerWidth = 240

export const userQuery = graphql(`
  query User {
    getUser {
      id
      email
      theme
      ...MainAppBarUser
      ...SideDrawerUser
    }
  }
`);

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    marginLeft: drawerWidth,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  container: {
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(4),
    }
  },
}));

export interface LayoutProps { }

/**
 * Overall app layout.
 * @param props 
 */
const Layout: React.FC<LayoutProps> = props => {
  const classes = useStyles();
  const theme = useTheme();
  const setThemeType = useThemeTypeSetter();
  const smallWindow = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [createUserDialogOpen, setCreateUserDialogOpen] = React.useState(false);
  useEffect(() => setDrawerOpen(!smallWindow), [smallWindow])
  const { user, isAuthenticated } = useAuth();
  const [, createUser] = useMutation(createUserMutation)
  const isLoggedIn = Boolean(isAuthenticated && user);
  const [{ data, error }, refetchUser] = useQuery({ query: userQuery, pause: !isLoggedIn })

  // If there's an error from the user query, create a new user.
  useEffect(() => {
    const effect = async () => {
      if (error) {
        // TODO: Verify this error is due to the user not existing in the database and not some other error.
        if (!user) return;  // user should exist at this point.
        if (user.profile.given_name && user.profile.family_name && user.profile.email) {
          // All the information we need exists, just create the user
          await createUser({ input: { email: user.profile.email, firstName: user.profile.given_name, lastName: user.profile.family_name, avatar: user.profile.picture } });
          refetchUser({ requestPolicy: 'network-only' });
        } else {
          // Ask the user for any additional account information
          setCreateUserDialogOpen(true)
        }
      }
    }
    effect();
  }, [error, createUser, user, refetchUser])

  // when the data from the userquery becomes available, set the theme based on their profile.
  useEffect(() => {
    if (data?.getUser.theme) {
      setThemeType(data.getUser.theme);
    }
  }, [data?.getUser.theme, setThemeType])

  const handleCloseCreateUserDialog = () => {
    refetchUser({ requestPolicy: 'network-only' });
    setCreateUserDialogOpen(false);
  };

  return (
    <>
      <MainAppBar
        drawer={isLoggedIn}
        drawerWidth={drawerWidth}
        drawerOpen={drawerOpen && isLoggedIn && !smallWindow}
        setDrawerOpen={setDrawerOpen}
        user={data?.getUser}
      />
      {isLoggedIn && (
        <SideDrawer
          drawerWidth={drawerWidth}
          user={data?.getUser}
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          variant={smallWindow ? "temporary" : "persistent"}
          anchor="left"
        />
      )}
      <CreateUserDialog open={createUserDialogOpen} onClose={handleCloseCreateUserDialog} />

      {/* Main Body */}
      <main className={clsx(classes.content, { [classes.contentShift]: drawerOpen && isLoggedIn && !smallWindow })}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" disableGutters={smallWindow} className={classes.container}>
          <>
            {props.children}
          </>
        </Container>
      </main>
    </>
  );
}

export default Layout;
