import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Avatar, { AvatarProps } from '@material-ui/core/Avatar';
import { FragmentType, useFragment } from '../../gql/fragment-masking'
import { graphql } from '../../gql'

export const GroupAvatarFragment = graphql(`
  fragment GroupAvatar on Group {
    name
    avatar
  }
`);

const useStyles = makeStyles((theme) => ({
  avatarSize: {
    width: (props: GroupAvatarProps) => theme.spacing(props.size),
    height: (props: GroupAvatarProps) => theme.spacing(props.size),
    fontSize: (props: GroupAvatarProps) => props.size * 4,
  },
}));

export interface GroupAvatarProps extends AvatarProps {
  group: FragmentType<typeof GroupAvatarFragment>,
  size: number;
}

const GroupAvatar: React.FC<GroupAvatarProps> = props => {
  const { group: groupProp, className, ...avatarProps } = props
  const classes = useStyles(props);
  const group = useFragment(GroupAvatarFragment, groupProp)

  return (
    <Avatar
      alt={`${group.name}`}
      src={group.avatar ?? undefined}
      className={clsx(className, classes.avatarSize)}
      {...avatarProps}
    >
      {group.name[0] ?? ""}
    </Avatar>
  )
}

export default GroupAvatar;
