import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AddIcon from '@material-ui/icons/Add';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import { useAuth } from "react-oidc-context";
import UserAvatar from '../components/UserAvatar'
import NotificationMenu from '../components/menus/NotificationMenu'
import CreateMenu from '../components/menus/CreateMenu'
import SettingsMenu from '../components/menus/SettingsMenu'
import { FragmentType, useFragment } from '../gql/fragment-masking'
import { graphql } from '../gql'

export const MainAppBarRightContainerUserFragment = graphql(`
  fragment MainAppBarRightContainerUser on User {
    ...UserAvatar
    firstName
    notifications {
      edges {
        node {
          read
        }
      }
      ...NotificationMenuConnection
    }
  }
`);

const useStyles = makeStyles((theme) => ({
  buttonText: {
    margin: theme.spacing(0, 0.5, 0, 1),
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export type MainAppBarProps = {
  user?: FragmentType<typeof MainAppBarRightContainerUserFragment>
}

const MainAppBarRightContainer: React.FC<MainAppBarProps> = props => {
  const classes = useStyles(props);
  const [settingsMenuAnchorEl, setSettingsMenuAnchorEl] = React.useState(null);
  const [createMenuAnchorEl, setCreateMenuAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [notificationMenuAnchorEl, setNotificationMenuAnchorEl] = React.useState(null);
  const { signinRedirect } = useAuth();
  const user = useFragment(MainAppBarRightContainerUserFragment, props.user)

  const isSettingsMenuOpen = Boolean(settingsMenuAnchorEl);
  const isCreateMenuOpen = Boolean(createMenuAnchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const isNotificationMenuOpen = Boolean(notificationMenuAnchorEl);

  const handleCreateMenuOpen = (event: any) => {
    setCreateMenuAnchorEl(event.currentTarget);
  };

  const handleSettingsMenuOpen = (event: any) => {
    setSettingsMenuAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleNotificationMenuOpen = (event: any) => {
    setNotificationMenuAnchorEl(event.currentTarget);
  };

  const handleCreateMenuClose = () => {
    setCreateMenuAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleNotificationMenuClose = () => {
    setNotificationMenuAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleSettingsMenuClose = () => {
    setSettingsMenuAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleLogin = () => {
    signinRedirect();
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: any) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = 'mobile-menu';
  const createMenuId = 'create-menu';
  const notificationMenuId = 'notification-menu';
  const settingsMenuId = 'settings-menu';

  const renderMobileMenu = user ? (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      getContentAnchorEl={null}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem
        aria-label="Open create menu"
        aria-controls={createMenuId}
        aria-haspopup="true"
        onClick={handleCreateMenuOpen}
      >
        <AddIcon />
        <span className={classes.buttonText}>
          Create
        </span>
      </MenuItem>
      <MenuItem
        aria-label="show notifications"
        aria-controls={notificationMenuId}
        aria-haspopup="true"
        onClick={handleNotificationMenuOpen}
      >
        <Badge badgeContent={user.notifications.edges.filter(item => !item.node.read).length} color="secondary">
          <NotificationsIcon />
        </Badge>
        <span className={classes.buttonText}>
          Notifications
        </span>
      </MenuItem>
      <MenuItem
        onClick={handleSettingsMenuOpen}
        aria-label="account of current user"
        aria-controls={settingsMenuId}
        aria-haspopup="true"
      >
        <AccountCircle />
        <span className={classes.buttonText}>
          Profile
        </span>
      </MenuItem>
    </Menu>
  ) : (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      getContentAnchorEl={null}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem
        onClick={handleLogin}
        aria-label="account of current user"
        aria-controls="primary-search-account-menu"
        aria-haspopup="true"
        color="inherit"
      >
        <AccountCircle />
        <span className={classes.buttonText}>
          Login
    </span>
      </MenuItem>
    </Menu>
  );

  return (
    <>
      <div className={classes.sectionDesktop}>
      {user ? (
        <>
          <IconButton
            aria-label="Open create menu"
            color="inherit"
            aria-controls={createMenuId}
            aria-haspopup="true"
            onClick={handleCreateMenuOpen}
          >
            <AddIcon />
          </IconButton>
          <IconButton
            aria-label="show notifications"
            color="inherit"
            aria-controls={notificationMenuId}
            aria-haspopup="true"
            onClick={handleNotificationMenuOpen}
          >
            <Badge badgeContent={user.notifications.edges.filter(item => !item.node.read).length} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <Button
            aria-label="account of current user"
            aria-controls={settingsMenuId}
            aria-haspopup="true"
            onClick={handleSettingsMenuOpen}
            color="inherit"
          >
            <UserAvatar user={user} size={5} />
            <span className={classes.buttonText}>
              {user.firstName}
            </span>
          </Button>
        </>
      ) : (
        <Button
          aria-label="login to your account"
          onClick={handleLogin}
          color="inherit"
        >
          <AccountCircle />
          <span className={classes.buttonText}>
            Login
          </span>
        </Button>
      )}
    </div>
    <div className={classes.sectionMobile}>
      <IconButton
        aria-label="show more"
        aria-controls={mobileMenuId}
        aria-haspopup="true"
        onClick={handleMobileMenuOpen}
        color="inherit"
      >
        <MoreIcon />
      </IconButton>
    </div>
      {renderMobileMenu}
      <CreateMenu
        anchorEl={createMenuAnchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        getContentAnchorEl={null}
        id={createMenuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isCreateMenuOpen}
        onClose={handleCreateMenuClose}
      />
      <SettingsMenu
        anchorEl={settingsMenuAnchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        getContentAnchorEl={null}
        id={settingsMenuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isSettingsMenuOpen}
        onClose={handleSettingsMenuClose}
      />
      {user && (
        <NotificationMenu
          notifications={user.notifications}
          handleNotificationMenuClose={handleNotificationMenuClose}
          id={notificationMenuId}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          getContentAnchorEl={null}
          keepMounted
          anchorEl={notificationMenuAnchorEl}
          open={isNotificationMenuOpen}
        />
      )}
    </>
  );
}

export default MainAppBarRightContainer;
