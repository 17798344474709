import React from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import EventIcon from '@material-ui/icons/Event';
import GroupAddIcon from '@material-ui/icons/GroupAdd';

const useStyles = makeStyles((theme) => ({
  buttonText: {
    margin: theme.spacing(0, 0.5, 0, 1),
  },
}));

export interface CreateMenuProps extends MenuProps {
  onClose?: () => void,
}

const CreateMenu: React.FC<CreateMenuProps> = props => {
  const classes = useStyles(props);
  const navigate = useNavigate();

  const handleClose = () => {
    if (props.onClose !== undefined) props.onClose();
  }

  return (
    <Menu {...props}>
      <MenuItem onClick={() => {
        navigate("/groups/create");
        handleClose();
      }}>
        <GroupAddIcon />
        <span className={classes.buttonText}>
          New Group
        </span>
      </MenuItem>
      <MenuItem onClick={() => {
        navigate("/events/create");
        handleClose();
      }}>
        <EventIcon />
        <span className={classes.buttonText}>
          New Event
        </span>
      </MenuItem>
    </Menu>
  )
}

export default CreateMenu;
