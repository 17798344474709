import React, { createContext, useContext, useMemo, useState } from 'react';
import { ThemeProvider as MuiThemeProvider, createTheme, PaletteType } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { Theme } from './gql/graphql'

const paletteTypeToTheme = (palletType?: string): Theme => {
  switch (palletType) {
    case 'light': return Theme.Light;
    case 'dark': return Theme.Dark;
    default: return Theme.Default;
  }
}

const themeToPaletteType = (theme: Theme): PaletteType | undefined => {
  switch (theme) {
    case Theme.Light:
      return 'light';
    case Theme.Dark:
      return 'dark';
    default: return;
  }
}

const storedThemeType = paletteTypeToTheme(localStorage.getItem('themeType') ?? undefined);

interface ThemeTypeSetter { (themeType: Theme): void };
const ThemeTypeSetterContext = createContext<ThemeTypeSetter>(() => { });

export const useThemeTypeSetter = () => {
  return useContext(ThemeTypeSetterContext);
};

export const ThemeProvider = (props: any) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [themeType, setThemeType] = useState<Theme>(storedThemeType)

  const theme = useMemo(() => {
    const type = themeToPaletteType(themeType) ?? (prefersDarkMode ? 'dark' : 'light');
    const theme = createTheme({
      typography: {
        h1: {
          fontSize: 28,
          fontWeight: 'bold',
          lineHeight: 1.5,
        },
      },
      palette: {
        type: type,
        primary: {
          main: '#1A3763',
        },
        secondary: {
          main: '#CD4E4E',
        },
        background: {
          default: type === 'light' ? '#E6E6E6' : '#181818',
          paper: type === 'light' ? '#FFF' : '#323232'
        },
      },
    })
    theme.overrides = {
      ...theme.overrides,
      MuiCssBaseline: {
        '@global': {
          ':root': {
            // Expose theme values as css variables to theme third party libraries.
            '--theme-pallete-primary-main': theme.palette.primary.main,
            '--theme-pallete-primary-light': theme.palette.primary.light,
            '--theme-pallete-secondary-main': theme.palette.primary.main,
            '--theme-pallete-divider': theme.palette.divider,
            '--theme-pallete-action-disabled': theme.palette.action.disabled,
            '--theme-pallete-action-disabledBackground': theme.palette.action.disabledBackground,
            '--theme-pallete-action-selected': theme.palette.action.selected,
            '--theme-pallete-background-paper': theme.palette.background.paper,
          }
        }
      }
    }
    return theme
  }, [themeType, prefersDarkMode]);

  return (
    <ThemeTypeSetterContext.Provider value={themeType => {
      setThemeType(themeType);
      const paletteType = themeToPaletteType(themeType);
      if (paletteType) {
        localStorage.setItem('themeType', paletteType)
      } else {
        localStorage.removeItem('themeType')
      }
    }}>
      <MuiThemeProvider theme={theme}>
        {props.children}
      </MuiThemeProvider>
    </ThemeTypeSetterContext.Provider>
  )
};
