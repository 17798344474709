/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel-plugin for production.
 */
const documents = {
    "\n  fragment UserAvatar on User {\n    firstName\n    lastName\n    avatar\n  }\n": types.UserAvatarFragmentDoc,
    "\n  fragment UserGroupInviteListItemGroup on Group {\n    id\n    name\n    ...GroupAvatar\n  }\n": types.UserGroupInviteListItemGroupFragmentDoc,
    "\n  mutation AcceptGroupInvite($input: AcceptGroupInviteInput!) {\n    acceptGroupInvite(input: $input) {\n      user {\n        id\n        groupInvites {\n          edges {\n            node {\n              ...UserGroupInviteListItemGroup\n            }\n          }\n        }\n        ...SideDrawerUser\n      }\n    }\n  }\n": types.AcceptGroupInviteDocument,
    "\n  mutation RejectGroupInvite($input: RejectGroupInviteInput!) {\n    rejectGroupInvite(input: $input) {\n      user {\n        id\n        groupInvites {\n          edges {\n            node {\n              ...UserGroupInviteListItemGroup\n            }\n          }\n        }\n      }\n    }\n  }\n": types.RejectGroupInviteDocument,
    "\n  fragment CalendarEvent on Event {\n    id\n    title\n    startTime\n    originalStartTime\n    endTime\n    allDay\n    rrule\n    recurringEvent {\n      id\n    }\n    deleted\n    ... on Practice {\n      viewerGuestInfo {\n        rsvpStatus\n      }\n    }\n  }\n": types.CalendarEventFragmentDoc,
    "\n  query GetCalendarEvents {\n    getUser {\n      id\n      events {\n        ...CalendarEvent\n      }\n      groups {\n        edges {\n          node {\n            id\n            events {\n              ...CalendarEvent\n            }\n          }\n        }\n      }\n    }\n  }\n": types.GetCalendarEventsDocument,
    "\n  fragment EventDetailsCardContent on Practice {\n    id\n    title\n    startTime\n    endTime\n    rrule\n    group {\n      id\n      name\n    }\n    description\n  }\n": types.EventDetailsCardContentFragmentDoc,
    "\n  query EventDetailsFormUser {\n    getUser {\n      id\n      groups {\n        edges {\n          node {\n            id\n            name\n            viewerMemberInfo {\n              role\n            }\n            ...GroupAvatar\n          }\n        }\n      }\n    }\n  }\n": types.EventDetailsFormUserDocument,
    "\n  fragment EventGuestsCardContent on Practice {\n    id\n    guests {\n      user {\n        id\n        firstName\n        lastName\n        ...UserAvatar\n      }\n      info {\n        rsvpStatus\n      }\n    }\n  }\n": types.EventGuestsCardContentFragmentDoc,
    "\n  fragment LineUpsCard on Practice {\n    id\n    guests {\n      user {\n        id\n        firstName\n        lastName\n        ...UserAvatar\n      }\n    }\n    lineUps {\n      lineUps {\n        label\n        type\n        seats {\n          label\n          user {\n            id\n            firstName\n            lastName\n            ...UserAvatar\n          }\n        }\n      }\n    }\n  }\n": types.LineUpsCardFragmentDoc,
    "\n  fragment GroupAvatar on Group {\n    name\n    avatar\n  }\n": types.GroupAvatarFragmentDoc,
    "\n  fragment GroupInviteListItemGroup on Group {\n    id\n    viewerMemberInfo {\n      role\n    }\n  }\n": types.GroupInviteListItemGroupFragmentDoc,
    "\n  fragment GroupInviteListItemInvite on GroupInvite {\n    user {\n      id\n      firstName\n      lastName\n      ...UserAvatar\n    }\n  }\n": types.GroupInviteListItemInviteFragmentDoc,
    "\n  mutation DeleteMemberInvite($input: DeleteMemberInviteInput!) {\n    deleteMemberInvite(input: $input) {\n      group {\n        id\n        invites {\n          ...GroupInviteListItemInvite\n        }\n      }\n    }\n  }\n": types.DeleteMemberInviteDocument,
    "\n  fragment GroupMemberListItemGroup on Group {\n    id\n    viewerMemberInfo {\n      role\n    }\n    ...GroupMemberRoleBadgeGroup\n  }\n": types.GroupMemberListItemGroupFragmentDoc,
    "\n  fragment GroupMemberListItemMember on GroupMember {\n    user {\n      id\n      firstName\n      lastName\n      ...UserAvatar\n    }\n    ...GroupMemberRoleBadgeGroupMember\n  }\n": types.GroupMemberListItemMemberFragmentDoc,
    "\n  mutation RemoveMember($input: RemoveMemberInput!) {\n    removeMember(input: $input) {\n      group {\n        id\n        members {\n          ...GroupMemberListItemMember\n        }\n      }\n    }\n  }\n": types.RemoveMemberDocument,
    "\n  mutation LeaveGroup($input: LeaveGroupInput!) {\n    leaveGroup(input: $input) {\n      group {\n        members {\n          ...GroupMemberListItemMember\n        }\n        ...GroupMemberListItemGroup\n      }\n      user {\n        id\n        ...SideDrawerUser\n      }\n    }\n  }\n": types.LeaveGroupDocument,
    "\n  fragment GroupMemberRoleBadgeGroup on Group {\n    id\n    viewerMemberInfo {\n      role\n    }\n  }\n": types.GroupMemberRoleBadgeGroupFragmentDoc,
    "\n  fragment GroupMemberRoleBadgeGroupMember on GroupMember {\n    user {\n      id\n    }\n    info {\n      role\n    }\n  }\n": types.GroupMemberRoleBadgeGroupMemberFragmentDoc,
    "\n  mutation UpdateMemberRole($input: UpdateMemberRoleInput!) {\n    updateMemberRole(input: $input) {\n      group {\n        id\n        members {\n          ...GroupMemberRoleBadgeGroupMember\n        }\n      }\n    }\n  }\n": types.UpdateMemberRoleDocument,
    "\n  fragment InviteGroupMemberDialog on Group {\n    id\n  }\n": types.InviteGroupMemberDialogFragmentDoc,
    "\n  mutation InviteNewMember($input: InviteNewMemberInput!) {\n    inviteNewMember(input: $input) {\n      group {\n        id\n        invites {\n          ...GroupInviteListItemInvite\n        }\n      }\n    }\n  }\n": types.InviteNewMemberDocument,
    "\n  query SearchForUsersToInvite($filter: UserFilterInput, $groupId: ID!) {\n    users(filter: $filter) {\n      edges {\n        node {\n          id\n          firstName\n          lastName\n          isInvitedToGroup(groupId: $groupId)\n          isMemberOfGroup(groupId: $groupId)\n          ...UserAvatar\n        }\n      }\n    }\n  }\n": types.SearchForUsersToInviteDocument,
    "\n  fragment GroupMembersTab on Group {\n    id\n    viewerMemberInfo {\n      role\n    }\n    members {\n      user {\n        id\n      }\n      ...GroupMemberListItemMember\n    }\n    invites {\n      ...GroupInviteListItemInvite\n    }\n    ...GroupMemberListItemGroup\n    ...GroupInviteListItemGroup\n    ...InviteGroupMemberDialog\n  }\n": types.GroupMembersTabFragmentDoc,
    "\n  fragment NotificationMenuConnection on NotificationConnection {\n    edges {\n      node {\n        id\n        read\n        createdAt\n        ... on GroupInviteNotification {\n          group {\n            id\n            name\n          }\n          invitedBy {\n            id\n            ...UserAvatar\n            firstName\n            lastName\n          }\n        }\n      }\n    }\n  }\n": types.NotificationMenuConnectionFragmentDoc,
    "\n  mutation MarkNotificationAsRead($input: MarkNotificationAsReadInput!) {\n    markNotificationAsRead(input: $input) {\n      notification {\n        id\n        read\n      }\n    }\n  }\n": types.MarkNotificationAsReadDocument,
    "\n  mutation MarkNotificationAsUnread($input: MarkNotificationAsUnreadInput!) {\n    markNotificationAsUnread(input: $input) {\n      notification {\n        id\n        read\n      }\n    }\n  }\n": types.MarkNotificationAsUnreadDocument,
    "\n  mutation MarkAllNotificationsAsRead {\n    markAllNotificationsAsRead {\n      notifications {\n        id\n        read\n      }\n    }\n  }\n": types.MarkAllNotificationsAsReadDocument,
    "\n  mutation CreateUser($input: CreateUserInput!) {\n    createUser(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n": types.CreateUserDocument,
    "\n  query User {\n    getUser {\n      id\n      email\n      theme\n      ...MainAppBarUser\n      ...SideDrawerUser\n    }\n  }\n": types.UserDocument,
    "\n  fragment MainAppBarUser on User {\n    ...MainAppBarRightContainerUser\n  }\n": types.MainAppBarUserFragmentDoc,
    "\n  query Search($filter: SearchInput) {\n    search(filter: $filter) {\n      edges {\n        node {\n          ... on Group {\n            id\n            name\n            ...GroupAvatar\n          }\n        }\n      }\n    }\n  }\n": types.SearchDocument,
    "\n  fragment MainAppBarRightContainerUser on User {\n    ...UserAvatar\n    firstName\n    notifications {\n      edges {\n        node {\n          read\n        }\n      }\n      ...NotificationMenuConnection\n    }\n  }\n": types.MainAppBarRightContainerUserFragmentDoc,
    "\n  fragment SideDrawerUser on User {\n    groups {\n      edges {\n        node {\n          id\n          name\n          ...GroupAvatar\n        }\n      }\n    }\n  }\n": types.SideDrawerUserFragmentDoc,
    "\n  query AccountPageUser {\n    getUser {\n      id\n      avatar\n      firstName\n      lastName\n      email\n      theme\n      groupInvites {\n        edges {\n          node {\n            ...UserGroupInviteListItemGroup\n          }\n        }\n      }\n      ...UserAvatar\n    }\n  }\n": types.AccountPageUserDocument,
    "\n  mutation UpdateUserAccountInfo($input: UpdateUserAccountInfoInput!) {\n    updateUserAccountInfo(input: $input) {\n      user {\n        id\n        email\n        firstName\n        lastName\n      }\n    }\n  }\n": types.UpdateUserAccountInfoDocument,
    "\n  mutation UploadUserAvatar($input: UploadUserAvatarInput!) {\n    uploadUserAvatar(input: $input) {\n      user {\n        id\n        avatar\n      }\n    }\n  }\n": types.UploadUserAvatarDocument,
    "\n  mutation RemoveUserAvatar {\n    removeUserAvatar {\n      user {\n        id\n        avatar\n      }\n    }\n  }\n": types.RemoveUserAvatarDocument,
    "\n  mutation UpdateUserTheme($input: UpdateUserThemeInput!) {\n    updateUserTheme(input: $input) {\n      user {\n        id\n        theme\n      }\n    }\n  }\n": types.UpdateUserThemeDocument,
    "\n  mutation CreateEvent($input: CreateEventInput!) {\n    createEvent(input: $input) {\n      event {\n        id\n      }\n    }\n  }\n": types.CreateEventDocument,
    "\n  mutation CreateGroup($input: CreateGroupInput!) {\n    createGroup(input: $input) {\n      group {\n        id\n      }\n    }\n  }\n": types.CreateGroupDocument,
    "\n  query Event($id: ID!) {\n    getEvent(id: $id) {\n      id\n      title\n      description\n      startTime\n      endTime\n      allDay\n      rrule\n      group {\n        id\n        viewerMemberInfo {\n          role\n        }\n      }\n      recurringEvent {\n        id\n        ... on Practice {\n          title\n          description\n          startTime\n          endTime\n          allDay\n          rrule\n          group {\n            id\n          }\n          ...EventDetailsCardContent\n        }\n      }\n      lineUps {\n        lineUps {\n          label\n        }\n      }\n      viewerGuestInfo {\n        rsvpStatus\n      }\n      ...EventDetailsCardContent\n      ...EventGuestsCardContent\n      ...LineUpsCard\n    }\n  }\n": types.EventDocument,
    "\n  mutation CreateRecurringEventOverride($input: CreateRecurringEventOverrideInput!) {\n    createRecurringEventOverride(input: $input) {\n      event {\n        id\n      }\n    }\n  }\n": types.CreateRecurringEventOverrideDocument,
    "\n  mutation UpdateEventDetails($input: UpdateEventDetailsInput!) {\n    updateEventDetails(input: $input) {\n      event {\n        id\n        recurringEvent {\n          id\n          ...EventDetailsCardContent\n        }\n        ...EventDetailsCardContent\n      }\n    }\n  }\n": types.UpdateEventDetailsDocument,
    "\n  mutation DeleteEvent($input: DeleteEventInput!) {\n    deleteEvent(input: $input) {\n      event {\n        id\n        deleted\n      }\n    }\n  }\n": types.DeleteEventDocument,
    "\n  mutation UpdateRSVPStatus($input: UpdateRSVPStatusInput!) {\n    updateRSVPStatus(input: $input) {\n      event {\n        id\n        viewerGuestInfo {\n          rsvpStatus\n        }\n        ...EventGuestsCardContent\n      }\n    }\n  }\n": types.UpdateRsvpStatusDocument,
    "\n  query Group($id: ID!) {\n    getGroup(id: $id) {\n      id\n      name\n      coverPhoto\n      viewerMemberInfo {\n        role\n      }\n      ...GroupAvatar\n      ...GroupMembersTab\n    }\n  }\n": types.GroupDocument,
    "\n  mutation UploadGroupCoverPhoto($input: UploadGroupCoverPhotoInput!) {\n    uploadGroupCoverPhoto(input: $input) {\n      group {\n        id\n        coverPhoto\n      }\n    }\n  }\n": types.UploadGroupCoverPhotoDocument,
    "\n  mutation UploadGroupAvatar($input: UploadGroupAvatarInput!) {\n    uploadGroupAvatar(input: $input) {\n      group {\n        id\n        avatar\n      }\n    }\n  }\n": types.UploadGroupAvatarDocument,
    "\n  mutation DeleteGroup($input: DeleteGroupInput!) {\n    deleteGroup(input: $input) {\n      group {\n        id\n      }\n    }\n  }\n": types.DeleteGroupDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment UserAvatar on User {\n    firstName\n    lastName\n    avatar\n  }\n"): (typeof documents)["\n  fragment UserAvatar on User {\n    firstName\n    lastName\n    avatar\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment UserGroupInviteListItemGroup on Group {\n    id\n    name\n    ...GroupAvatar\n  }\n"): (typeof documents)["\n  fragment UserGroupInviteListItemGroup on Group {\n    id\n    name\n    ...GroupAvatar\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AcceptGroupInvite($input: AcceptGroupInviteInput!) {\n    acceptGroupInvite(input: $input) {\n      user {\n        id\n        groupInvites {\n          edges {\n            node {\n              ...UserGroupInviteListItemGroup\n            }\n          }\n        }\n        ...SideDrawerUser\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation AcceptGroupInvite($input: AcceptGroupInviteInput!) {\n    acceptGroupInvite(input: $input) {\n      user {\n        id\n        groupInvites {\n          edges {\n            node {\n              ...UserGroupInviteListItemGroup\n            }\n          }\n        }\n        ...SideDrawerUser\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RejectGroupInvite($input: RejectGroupInviteInput!) {\n    rejectGroupInvite(input: $input) {\n      user {\n        id\n        groupInvites {\n          edges {\n            node {\n              ...UserGroupInviteListItemGroup\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation RejectGroupInvite($input: RejectGroupInviteInput!) {\n    rejectGroupInvite(input: $input) {\n      user {\n        id\n        groupInvites {\n          edges {\n            node {\n              ...UserGroupInviteListItemGroup\n            }\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CalendarEvent on Event {\n    id\n    title\n    startTime\n    originalStartTime\n    endTime\n    allDay\n    rrule\n    recurringEvent {\n      id\n    }\n    deleted\n    ... on Practice {\n      viewerGuestInfo {\n        rsvpStatus\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment CalendarEvent on Event {\n    id\n    title\n    startTime\n    originalStartTime\n    endTime\n    allDay\n    rrule\n    recurringEvent {\n      id\n    }\n    deleted\n    ... on Practice {\n      viewerGuestInfo {\n        rsvpStatus\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetCalendarEvents {\n    getUser {\n      id\n      events {\n        ...CalendarEvent\n      }\n      groups {\n        edges {\n          node {\n            id\n            events {\n              ...CalendarEvent\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetCalendarEvents {\n    getUser {\n      id\n      events {\n        ...CalendarEvent\n      }\n      groups {\n        edges {\n          node {\n            id\n            events {\n              ...CalendarEvent\n            }\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment EventDetailsCardContent on Practice {\n    id\n    title\n    startTime\n    endTime\n    rrule\n    group {\n      id\n      name\n    }\n    description\n  }\n"): (typeof documents)["\n  fragment EventDetailsCardContent on Practice {\n    id\n    title\n    startTime\n    endTime\n    rrule\n    group {\n      id\n      name\n    }\n    description\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query EventDetailsFormUser {\n    getUser {\n      id\n      groups {\n        edges {\n          node {\n            id\n            name\n            viewerMemberInfo {\n              role\n            }\n            ...GroupAvatar\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query EventDetailsFormUser {\n    getUser {\n      id\n      groups {\n        edges {\n          node {\n            id\n            name\n            viewerMemberInfo {\n              role\n            }\n            ...GroupAvatar\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment EventGuestsCardContent on Practice {\n    id\n    guests {\n      user {\n        id\n        firstName\n        lastName\n        ...UserAvatar\n      }\n      info {\n        rsvpStatus\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment EventGuestsCardContent on Practice {\n    id\n    guests {\n      user {\n        id\n        firstName\n        lastName\n        ...UserAvatar\n      }\n      info {\n        rsvpStatus\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment LineUpsCard on Practice {\n    id\n    guests {\n      user {\n        id\n        firstName\n        lastName\n        ...UserAvatar\n      }\n    }\n    lineUps {\n      lineUps {\n        label\n        type\n        seats {\n          label\n          user {\n            id\n            firstName\n            lastName\n            ...UserAvatar\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment LineUpsCard on Practice {\n    id\n    guests {\n      user {\n        id\n        firstName\n        lastName\n        ...UserAvatar\n      }\n    }\n    lineUps {\n      lineUps {\n        label\n        type\n        seats {\n          label\n          user {\n            id\n            firstName\n            lastName\n            ...UserAvatar\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment GroupAvatar on Group {\n    name\n    avatar\n  }\n"): (typeof documents)["\n  fragment GroupAvatar on Group {\n    name\n    avatar\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment GroupInviteListItemGroup on Group {\n    id\n    viewerMemberInfo {\n      role\n    }\n  }\n"): (typeof documents)["\n  fragment GroupInviteListItemGroup on Group {\n    id\n    viewerMemberInfo {\n      role\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment GroupInviteListItemInvite on GroupInvite {\n    user {\n      id\n      firstName\n      lastName\n      ...UserAvatar\n    }\n  }\n"): (typeof documents)["\n  fragment GroupInviteListItemInvite on GroupInvite {\n    user {\n      id\n      firstName\n      lastName\n      ...UserAvatar\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteMemberInvite($input: DeleteMemberInviteInput!) {\n    deleteMemberInvite(input: $input) {\n      group {\n        id\n        invites {\n          ...GroupInviteListItemInvite\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteMemberInvite($input: DeleteMemberInviteInput!) {\n    deleteMemberInvite(input: $input) {\n      group {\n        id\n        invites {\n          ...GroupInviteListItemInvite\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment GroupMemberListItemGroup on Group {\n    id\n    viewerMemberInfo {\n      role\n    }\n    ...GroupMemberRoleBadgeGroup\n  }\n"): (typeof documents)["\n  fragment GroupMemberListItemGroup on Group {\n    id\n    viewerMemberInfo {\n      role\n    }\n    ...GroupMemberRoleBadgeGroup\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment GroupMemberListItemMember on GroupMember {\n    user {\n      id\n      firstName\n      lastName\n      ...UserAvatar\n    }\n    ...GroupMemberRoleBadgeGroupMember\n  }\n"): (typeof documents)["\n  fragment GroupMemberListItemMember on GroupMember {\n    user {\n      id\n      firstName\n      lastName\n      ...UserAvatar\n    }\n    ...GroupMemberRoleBadgeGroupMember\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RemoveMember($input: RemoveMemberInput!) {\n    removeMember(input: $input) {\n      group {\n        id\n        members {\n          ...GroupMemberListItemMember\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation RemoveMember($input: RemoveMemberInput!) {\n    removeMember(input: $input) {\n      group {\n        id\n        members {\n          ...GroupMemberListItemMember\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation LeaveGroup($input: LeaveGroupInput!) {\n    leaveGroup(input: $input) {\n      group {\n        members {\n          ...GroupMemberListItemMember\n        }\n        ...GroupMemberListItemGroup\n      }\n      user {\n        id\n        ...SideDrawerUser\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation LeaveGroup($input: LeaveGroupInput!) {\n    leaveGroup(input: $input) {\n      group {\n        members {\n          ...GroupMemberListItemMember\n        }\n        ...GroupMemberListItemGroup\n      }\n      user {\n        id\n        ...SideDrawerUser\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment GroupMemberRoleBadgeGroup on Group {\n    id\n    viewerMemberInfo {\n      role\n    }\n  }\n"): (typeof documents)["\n  fragment GroupMemberRoleBadgeGroup on Group {\n    id\n    viewerMemberInfo {\n      role\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment GroupMemberRoleBadgeGroupMember on GroupMember {\n    user {\n      id\n    }\n    info {\n      role\n    }\n  }\n"): (typeof documents)["\n  fragment GroupMemberRoleBadgeGroupMember on GroupMember {\n    user {\n      id\n    }\n    info {\n      role\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateMemberRole($input: UpdateMemberRoleInput!) {\n    updateMemberRole(input: $input) {\n      group {\n        id\n        members {\n          ...GroupMemberRoleBadgeGroupMember\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateMemberRole($input: UpdateMemberRoleInput!) {\n    updateMemberRole(input: $input) {\n      group {\n        id\n        members {\n          ...GroupMemberRoleBadgeGroupMember\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment InviteGroupMemberDialog on Group {\n    id\n  }\n"): (typeof documents)["\n  fragment InviteGroupMemberDialog on Group {\n    id\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation InviteNewMember($input: InviteNewMemberInput!) {\n    inviteNewMember(input: $input) {\n      group {\n        id\n        invites {\n          ...GroupInviteListItemInvite\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation InviteNewMember($input: InviteNewMemberInput!) {\n    inviteNewMember(input: $input) {\n      group {\n        id\n        invites {\n          ...GroupInviteListItemInvite\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query SearchForUsersToInvite($filter: UserFilterInput, $groupId: ID!) {\n    users(filter: $filter) {\n      edges {\n        node {\n          id\n          firstName\n          lastName\n          isInvitedToGroup(groupId: $groupId)\n          isMemberOfGroup(groupId: $groupId)\n          ...UserAvatar\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query SearchForUsersToInvite($filter: UserFilterInput, $groupId: ID!) {\n    users(filter: $filter) {\n      edges {\n        node {\n          id\n          firstName\n          lastName\n          isInvitedToGroup(groupId: $groupId)\n          isMemberOfGroup(groupId: $groupId)\n          ...UserAvatar\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment GroupMembersTab on Group {\n    id\n    viewerMemberInfo {\n      role\n    }\n    members {\n      user {\n        id\n      }\n      ...GroupMemberListItemMember\n    }\n    invites {\n      ...GroupInviteListItemInvite\n    }\n    ...GroupMemberListItemGroup\n    ...GroupInviteListItemGroup\n    ...InviteGroupMemberDialog\n  }\n"): (typeof documents)["\n  fragment GroupMembersTab on Group {\n    id\n    viewerMemberInfo {\n      role\n    }\n    members {\n      user {\n        id\n      }\n      ...GroupMemberListItemMember\n    }\n    invites {\n      ...GroupInviteListItemInvite\n    }\n    ...GroupMemberListItemGroup\n    ...GroupInviteListItemGroup\n    ...InviteGroupMemberDialog\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment NotificationMenuConnection on NotificationConnection {\n    edges {\n      node {\n        id\n        read\n        createdAt\n        ... on GroupInviteNotification {\n          group {\n            id\n            name\n          }\n          invitedBy {\n            id\n            ...UserAvatar\n            firstName\n            lastName\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment NotificationMenuConnection on NotificationConnection {\n    edges {\n      node {\n        id\n        read\n        createdAt\n        ... on GroupInviteNotification {\n          group {\n            id\n            name\n          }\n          invitedBy {\n            id\n            ...UserAvatar\n            firstName\n            lastName\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation MarkNotificationAsRead($input: MarkNotificationAsReadInput!) {\n    markNotificationAsRead(input: $input) {\n      notification {\n        id\n        read\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation MarkNotificationAsRead($input: MarkNotificationAsReadInput!) {\n    markNotificationAsRead(input: $input) {\n      notification {\n        id\n        read\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation MarkNotificationAsUnread($input: MarkNotificationAsUnreadInput!) {\n    markNotificationAsUnread(input: $input) {\n      notification {\n        id\n        read\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation MarkNotificationAsUnread($input: MarkNotificationAsUnreadInput!) {\n    markNotificationAsUnread(input: $input) {\n      notification {\n        id\n        read\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation MarkAllNotificationsAsRead {\n    markAllNotificationsAsRead {\n      notifications {\n        id\n        read\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation MarkAllNotificationsAsRead {\n    markAllNotificationsAsRead {\n      notifications {\n        id\n        read\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateUser($input: CreateUserInput!) {\n    createUser(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateUser($input: CreateUserInput!) {\n    createUser(input: $input) {\n      user {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query User {\n    getUser {\n      id\n      email\n      theme\n      ...MainAppBarUser\n      ...SideDrawerUser\n    }\n  }\n"): (typeof documents)["\n  query User {\n    getUser {\n      id\n      email\n      theme\n      ...MainAppBarUser\n      ...SideDrawerUser\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment MainAppBarUser on User {\n    ...MainAppBarRightContainerUser\n  }\n"): (typeof documents)["\n  fragment MainAppBarUser on User {\n    ...MainAppBarRightContainerUser\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Search($filter: SearchInput) {\n    search(filter: $filter) {\n      edges {\n        node {\n          ... on Group {\n            id\n            name\n            ...GroupAvatar\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query Search($filter: SearchInput) {\n    search(filter: $filter) {\n      edges {\n        node {\n          ... on Group {\n            id\n            name\n            ...GroupAvatar\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment MainAppBarRightContainerUser on User {\n    ...UserAvatar\n    firstName\n    notifications {\n      edges {\n        node {\n          read\n        }\n      }\n      ...NotificationMenuConnection\n    }\n  }\n"): (typeof documents)["\n  fragment MainAppBarRightContainerUser on User {\n    ...UserAvatar\n    firstName\n    notifications {\n      edges {\n        node {\n          read\n        }\n      }\n      ...NotificationMenuConnection\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SideDrawerUser on User {\n    groups {\n      edges {\n        node {\n          id\n          name\n          ...GroupAvatar\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment SideDrawerUser on User {\n    groups {\n      edges {\n        node {\n          id\n          name\n          ...GroupAvatar\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AccountPageUser {\n    getUser {\n      id\n      avatar\n      firstName\n      lastName\n      email\n      theme\n      groupInvites {\n        edges {\n          node {\n            ...UserGroupInviteListItemGroup\n          }\n        }\n      }\n      ...UserAvatar\n    }\n  }\n"): (typeof documents)["\n  query AccountPageUser {\n    getUser {\n      id\n      avatar\n      firstName\n      lastName\n      email\n      theme\n      groupInvites {\n        edges {\n          node {\n            ...UserGroupInviteListItemGroup\n          }\n        }\n      }\n      ...UserAvatar\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateUserAccountInfo($input: UpdateUserAccountInfoInput!) {\n    updateUserAccountInfo(input: $input) {\n      user {\n        id\n        email\n        firstName\n        lastName\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateUserAccountInfo($input: UpdateUserAccountInfoInput!) {\n    updateUserAccountInfo(input: $input) {\n      user {\n        id\n        email\n        firstName\n        lastName\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UploadUserAvatar($input: UploadUserAvatarInput!) {\n    uploadUserAvatar(input: $input) {\n      user {\n        id\n        avatar\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UploadUserAvatar($input: UploadUserAvatarInput!) {\n    uploadUserAvatar(input: $input) {\n      user {\n        id\n        avatar\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RemoveUserAvatar {\n    removeUserAvatar {\n      user {\n        id\n        avatar\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation RemoveUserAvatar {\n    removeUserAvatar {\n      user {\n        id\n        avatar\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateUserTheme($input: UpdateUserThemeInput!) {\n    updateUserTheme(input: $input) {\n      user {\n        id\n        theme\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateUserTheme($input: UpdateUserThemeInput!) {\n    updateUserTheme(input: $input) {\n      user {\n        id\n        theme\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateEvent($input: CreateEventInput!) {\n    createEvent(input: $input) {\n      event {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateEvent($input: CreateEventInput!) {\n    createEvent(input: $input) {\n      event {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateGroup($input: CreateGroupInput!) {\n    createGroup(input: $input) {\n      group {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateGroup($input: CreateGroupInput!) {\n    createGroup(input: $input) {\n      group {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Event($id: ID!) {\n    getEvent(id: $id) {\n      id\n      title\n      description\n      startTime\n      endTime\n      allDay\n      rrule\n      group {\n        id\n        viewerMemberInfo {\n          role\n        }\n      }\n      recurringEvent {\n        id\n        ... on Practice {\n          title\n          description\n          startTime\n          endTime\n          allDay\n          rrule\n          group {\n            id\n          }\n          ...EventDetailsCardContent\n        }\n      }\n      lineUps {\n        lineUps {\n          label\n        }\n      }\n      viewerGuestInfo {\n        rsvpStatus\n      }\n      ...EventDetailsCardContent\n      ...EventGuestsCardContent\n      ...LineUpsCard\n    }\n  }\n"): (typeof documents)["\n  query Event($id: ID!) {\n    getEvent(id: $id) {\n      id\n      title\n      description\n      startTime\n      endTime\n      allDay\n      rrule\n      group {\n        id\n        viewerMemberInfo {\n          role\n        }\n      }\n      recurringEvent {\n        id\n        ... on Practice {\n          title\n          description\n          startTime\n          endTime\n          allDay\n          rrule\n          group {\n            id\n          }\n          ...EventDetailsCardContent\n        }\n      }\n      lineUps {\n        lineUps {\n          label\n        }\n      }\n      viewerGuestInfo {\n        rsvpStatus\n      }\n      ...EventDetailsCardContent\n      ...EventGuestsCardContent\n      ...LineUpsCard\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateRecurringEventOverride($input: CreateRecurringEventOverrideInput!) {\n    createRecurringEventOverride(input: $input) {\n      event {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateRecurringEventOverride($input: CreateRecurringEventOverrideInput!) {\n    createRecurringEventOverride(input: $input) {\n      event {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateEventDetails($input: UpdateEventDetailsInput!) {\n    updateEventDetails(input: $input) {\n      event {\n        id\n        recurringEvent {\n          id\n          ...EventDetailsCardContent\n        }\n        ...EventDetailsCardContent\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateEventDetails($input: UpdateEventDetailsInput!) {\n    updateEventDetails(input: $input) {\n      event {\n        id\n        recurringEvent {\n          id\n          ...EventDetailsCardContent\n        }\n        ...EventDetailsCardContent\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteEvent($input: DeleteEventInput!) {\n    deleteEvent(input: $input) {\n      event {\n        id\n        deleted\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteEvent($input: DeleteEventInput!) {\n    deleteEvent(input: $input) {\n      event {\n        id\n        deleted\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateRSVPStatus($input: UpdateRSVPStatusInput!) {\n    updateRSVPStatus(input: $input) {\n      event {\n        id\n        viewerGuestInfo {\n          rsvpStatus\n        }\n        ...EventGuestsCardContent\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateRSVPStatus($input: UpdateRSVPStatusInput!) {\n    updateRSVPStatus(input: $input) {\n      event {\n        id\n        viewerGuestInfo {\n          rsvpStatus\n        }\n        ...EventGuestsCardContent\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query Group($id: ID!) {\n    getGroup(id: $id) {\n      id\n      name\n      coverPhoto\n      viewerMemberInfo {\n        role\n      }\n      ...GroupAvatar\n      ...GroupMembersTab\n    }\n  }\n"): (typeof documents)["\n  query Group($id: ID!) {\n    getGroup(id: $id) {\n      id\n      name\n      coverPhoto\n      viewerMemberInfo {\n        role\n      }\n      ...GroupAvatar\n      ...GroupMembersTab\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UploadGroupCoverPhoto($input: UploadGroupCoverPhotoInput!) {\n    uploadGroupCoverPhoto(input: $input) {\n      group {\n        id\n        coverPhoto\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UploadGroupCoverPhoto($input: UploadGroupCoverPhotoInput!) {\n    uploadGroupCoverPhoto(input: $input) {\n      group {\n        id\n        coverPhoto\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UploadGroupAvatar($input: UploadGroupAvatarInput!) {\n    uploadGroupAvatar(input: $input) {\n      group {\n        id\n        avatar\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UploadGroupAvatar($input: UploadGroupAvatarInput!) {\n    uploadGroupAvatar(input: $input) {\n      group {\n        id\n        avatar\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteGroup($input: DeleteGroupInput!) {\n    deleteGroup(input: $input) {\n      group {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteGroup($input: DeleteGroupInput!) {\n    deleteGroup(input: $input) {\n      group {\n        id\n      }\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;